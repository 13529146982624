/* global createUnityInstance */
import React, { useEffect, useRef, useState } from 'react';
import './UnityGame.css';

const UnityGame = () => {
  const containerRef = useRef(null);
  const canvasRef = useRef(null);
  const loadingBarRef = useRef(null);
  const progressBarFullRef = useRef(null);
  const fullscreenButtonRef = useRef(null);
  const warningBannerRef = useRef(null);
  const [unityInstance, setUnityInstance] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const style = document.createElement('style');
    style.textContent = `
      #unity-logo { background: url('/unity-game/TemplateData/unity-logo-dark.png') no-repeat center; }
      #unity-progress-bar-empty { background: url('/unity-game/TemplateData/progress-bar-empty-dark.png') no-repeat center; }
      #unity-progress-bar-full { background: url('/unity-game/TemplateData/progress-bar-full-dark.png') no-repeat center; }
      #unity-webgl-logo { background: url('/unity-game/TemplateData/webgl-logo.png') no-repeat center; }
      #unity-fullscreen-button { background: url('/unity-game/TemplateData/fullscreen-button.png') no-repeat center; }
    `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, []);

  useEffect(() => {
    const container = containerRef.current;
    const canvas = canvasRef.current;
    const loadingBar = loadingBarRef.current;
    const progressBarFull = progressBarFullRef.current;
    const fullscreenButton = fullscreenButtonRef.current;
    const warningBanner = warningBannerRef.current;

    function unityShowBanner(msg, type) {
      function updateBannerVisibility() {
        warningBanner.style.display = warningBanner.children.length ? 'block' : 'none';
      }
      var div = document.createElement('div');
      div.innerHTML = msg;
      warningBanner.appendChild(div);
      if (type === 'error') div.style = 'background: red; padding: 10px;';
      else if (type === 'warning') {
        div.style = 'background: yellow; padding: 10px;';
        setTimeout(() => {
          warningBanner.removeChild(div);
          updateBannerVisibility();
        }, 5000);
      }
      updateBannerVisibility();
    }

    var buildUrl = "unity-game/Build";
    var loaderUrl = buildUrl + "/Build.loader.js";
    var config = {
      dataUrl: buildUrl + "/Build.data.gz",
      frameworkUrl: buildUrl + "/Build.framework.js.gz",
      codeUrl: buildUrl + "/Build.wasm.gz",
      streamingAssetsUrl: "StreamingAssets",
      companyName: "DefaultCompany",
      productName: "F_dist",
      productVersion: "0.1",
      showBanner: unityShowBanner,
    };

    if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
      var meta = document.createElement('meta');
      meta.name = 'viewport';
      meta.content = 'width=device-width, height=device-height, initial-scale=1.0, user-scalable=no, shrink-to-fit=yes';
      document.getElementsByTagName('head')[0].appendChild(meta);
      container.className = "unity-mobile";
      canvas.className = "unity-mobile";
    } else {
      canvas.style.width = "1920px";
      canvas.style.height = "1080px";
    }

    loadingBar.style.display = "block";

    var script = document.createElement("script");
    script.src = loaderUrl;
    script.onload = () => {
      if (typeof createUnityInstance === "function") {
        createUnityInstance(canvas, config, (progress) => {
          progressBarFull.style.width = 100 * progress + "%";
        }).then((unityInstance) => {
          setUnityInstance(unityInstance);
          loadingBar.style.display = "none";
          setIsLoaded(true);
        }).catch((message) => {
          alert(message);
        });
      } else {
        console.error("createUnityInstance is not available");
      }
    };

    document.body.appendChild(script);

    return () => {
      if (unityInstance) {
        unityInstance.Quit();
      }
      document.body.removeChild(script);
    };
  }, []);

  const handleFullscreen = () => {
    if (unityInstance) {
      unityInstance.SetFullscreen(1);
    }
  };

  return (
    <div id="unity-container" ref={containerRef} className="unity-desktop">
      <canvas id="unity-canvas" ref={canvasRef} width={1920} height={1080} tabIndex="-1"></canvas>
      <div id="unity-loading-bar" ref={loadingBarRef}>
        <div id="unity-logo"></div>
        <div id="unity-progress-bar-empty">
          <div id="unity-progress-bar-full" ref={progressBarFullRef}></div>
        </div>
      </div>
      <div id="unity-warning" ref={warningBannerRef}> </div>
      <div id="unity-footer">
        <div id="unity-webgl-logo"></div>
        <div id="unity-fullscreen-button" ref={fullscreenButtonRef} onClick={handleFullscreen}></div>
        <div id="unity-build-title">F_dist</div>
      </div>
    </div>
  );
};

export default UnityGame;
